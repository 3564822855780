
import {Component, Prop, Vue} from "vue-property-decorator";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import PersonelForm from "@/components/forms/PersonelForm.vue";
import {DigerPersonelEntity} from "@/entity/DigerPersonelEntity";
import {AvukatEntity} from "@/entity/AvukatEntity";

@Component({
  components: {FormDialog, PersonelForm}
})
export default class PersonelInfo extends Vue {
  @Prop()  item!: AvukatEntity | DigerPersonelEntity;
  @Prop({}) action!: string;

  load() {
    this.$emit('load')
  }
}
