
import {Component, Vue, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {mixins} from "vue-class-component";
import FormWrapper from "@/components/FormWrapper.vue";
import SehirPicker from "@/components/pickers/SehirPicker.vue";
import IlcePicker from "@/components/pickers/IlcePicker.vue";
import NufusCuzdaniForm from "@/components/forms/NufusCuzdaniForm.vue";
import AvukatKimlikKartiForm from "@/components/forms/AvukatKimlikKartiForm.vue";
import PasaportForm from "@/components/forms/PasaportForm.vue";
import EhliyetForm from "@/components/forms/EhliyetForm.vue";

@Component({
  components: {
    EhliyetForm,
    PasaportForm,
    AvukatKimlikKartiForm,
    NufusCuzdaniForm,
    IlcePicker,
    SehirPicker,
    FormWrapper,
  }
})
export default class KimlikBilgisiEkleForm extends mixins(ObjectInputMixin) {
  @Prop({required: true}) kisiId!: number;
  @Prop({required: true}) avukat!: boolean;

  action!: string;
  selectedType = -1;

  turler = [
    {tur: 'Avukat Kimlik Kartı', id: 1, key: "avukat_kimlik_karti"},
    {tur: 'Nüfus Cüzdanı', id: 2, key: "nufus_cuzdani"},
    {tur: 'Pasaport', id: 3, key: "pasaport"},
    {tur: 'Ehliyet', id: 4, key: "ehliyet"},
  ];

  load() {
    this.$emit("load");
  }

  async save() {
    let action = "";
    let data = {};
    let update = false;
    let response;
    if (this.selectedType == 1) {
      action = this.action + "avukat-kimlik-karti";
      data = this.localValue.avukat_kimlik_karti;
      if (this.localValue.avukat_kimlik_karti.id)
        update = true;
    } else if (this.selectedType == 2) {
      action = this.action + "nufus-cuzdani";
      data = this.localValue.nufus_cuzdani;
      if (this.localValue.nufus_cuzdani.id)
        update = true;
    } else if (this.selectedType == 3) {
      action = this.action + "pasaport";
      data = this.localValue.pasaport;
      if (this.localValue.pasaport.id)
        update = true;
    } else if (this.selectedType == 4) {
      action = this.action + "ehliyet";
      data = this.localValue.ehliyet;
      if (this.localValue.ehliyet.id)
        update = true;
    }
    if (update) {
      await this.$http.put(action, data);
      update = false;
    } else {
      response = await this.$http.post(action, data);
    }
    this.selectedType = -1;
    this.$emit("onSuccess");
  }

  mounted() {
    if (this.avukat) {
      this.action = "/api/v1/personel/" + this.kisiId + "/";
    } else {
      this.action = "/api/v1/kisi/" + this.kisiId + "/";
      this.turler.shift();
    }
    this.turler.forEach((tur: any) => {
      if (this.localValue[tur.key] == undefined) {
        this.localValue[tur.key] = {}
      }
    });
  }

  close() {
    this.selectedType = -1;
    this.$emit("onClose");
  }
};
