
import {Component, Mixins} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import SehirPicker from "@/components/pickers/SehirPicker.vue";
import IlcePicker from "@/components/pickers/IlcePicker.vue";

@Component({
  components: {
    IlcePicker,
    SehirPicker,
    Dates,
  }
})
export default class AvukatKimlikKartiForm extends Mixins(ObjectInputMixin) {

};
