
import {Component, Mixins} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({
  components: {
    Dates,
  }
})
export default class EhliyetForm extends Mixins(ObjectInputMixin) {

};
