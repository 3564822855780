
import {Component, Prop, Mixins, Vue} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import NufusCuzdaniCard from "@/components/kisi/kimlik/NufusCuzdaniCard.vue";
import AvukatKimlikKartiCard from "@/components/kisi/kimlik/AvukatKimlikKartiCard.vue";
import PasaportCard from "@/components/kisi/kimlik/PasaportCard.vue";
import EhliyetCard from "@/components/kisi/kimlik/EhliyetCard.vue";
import KimlikBilgisiEkleForm from "@/components/forms/KimlikBilgisiEkleForm.vue";
import {AvukatKimlikKartiEntity} from "@/entity/AvukatKimlikKartiEntity";
import {NufusCuzdaniEntity} from "@/entity/NufusCuzdaniEntity";
import {PasaportEntity} from "@/entity/PasaportEntity";
import {EhliyetEntity} from "@/entity/EhliyetEntity";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import {AvukatEntity} from "@/entity/AvukatEntity";
import {KisiEntity} from "@/entity/KisiEntity";

class requestEntity {
  avukat_kimlik_karti !: AvukatKimlikKartiEntity;
  nufus_cuzdani !: NufusCuzdaniEntity;
  ehliyet !: EhliyetEntity;
  pasaport !: PasaportEntity;
}

@Component({
  components: {
    FormDialog,
    KimlikBilgisiEkleForm,
    EhliyetCard,
    PasaportCard,
    AvukatKimlikKartiCard,
    NufusCuzdaniCard
  }
})
export default class KimlikBilgileriList extends Mixins(ObjectInputMixin) {
  @Prop({required: true}) kisiId!: number;
  @Prop({required: true}) avukat!: boolean;
  localValue!: AvukatEntity | KisiEntity;

  load() {
    this.$emit('load');
  }
  isAuthorized(keySuffix: string) {
    let ap = this.$store.state.activeProcesses;
    let authKey = null;

    if (keySuffix[0] !== '-') {
      keySuffix = '-' + keySuffix;
    }

    if (ap.filter((item: any) => item.key ==="takip-ac").length > 0) {
      authKey = "takip-ac";
    } else if (ap.filter((item: any) => item.key ==="takip-duzenle").length > 0) {
      if (ap.filter((item: any) => item.key ==="borclu-detay").length > 0) {
        authKey = "borclu" + keySuffix;
      }else if (ap.filter((item: any) => item.key ==="alacakli-detay").length > 0) {
        authKey = "muvekkil" + keySuffix;
      }
    }else if(ap.filter((item : any) => item.key === "muvekkil-detay").length > 0){
      authKey = "muvekkil" + keySuffix
    }
    else if(ap.filter((item : any) => item.key === "buro-detay").length > 0){
      authKey = "calisan" + keySuffix
    }

    if (!authKey) {
      return false;
    }

    return this.$helper.isAuthorized(authKey)
  }

}
