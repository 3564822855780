
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {PasaportEntity} from "@/entity/PasaportEntity";

@Component({
  components: {}
})

export default class PasaportCard extends Mixins(ObjectInputMixin) {
  localValue!: PasaportEntity;
}
