
import {Component, Mixins, Prop} from "vue-property-decorator";
import {EhliyetEntity} from "../../../entity/EhliyetEntity";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({
  components: {}
})

export default class EhliyetCard extends Mixins(ObjectInputMixin) {
  localValue!: EhliyetEntity;
}
