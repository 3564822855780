
import {Component, Vue} from "vue-property-decorator";

import PersonelInfo from "@/components/infos/PersonelInfo.vue";
import IletisimList from "@/components/lists/IletisimList.vue";
import AdresList from "@/components/lists/AdresList.vue";
import BankaHesabiList from "@/components/lists/BankaHesabiList.vue";
import {AvukatEntity} from "@/entity/AvukatEntity";
import {DigerPersonelEntity} from "@/entity/DigerPersonelEntity";
import {PersonelTuru} from "@/enum/PersonelTuru";
import {AxiosResponse} from "axios";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import KimlikBilgileriList from "@/components/lists/KimlikBilgileriList.vue";

@Component({
  components: {
    KimlikBilgileriList,
    FormDialog,
    PersonelInfo,
    IletisimList,
    AdresList,
    BankaHesabiList,
  }
})
export default class PersonelDetayView extends Vue {
  item: AvukatEntity | DigerPersonelEntity | null = null;

  get action() {
    if (this.item) {
      return "/api/v1/personel/" + this.item.id;
    }
  }

  mounted() {
    let activeProcess = {id: this.$route.params.id, key: 'personel-detay', isRoot: true}
    this.$store.commit('setActiveProcess', activeProcess);
    this.load();
  }

  load() {
    let index: number = parseInt(this.$route.params.id);
    this.$http
        .get('/api/v1/personel/' + index)
        .then((response: AxiosResponse<AvukatEntity | DigerPersonelEntity>) => (this.item = response.data));
  }

  isAuthorized(keySuffix: string) {
    let ap = this.$store.state.activeProcesses;
    let authKey = null;

    if (keySuffix[0] !== '-') {
      keySuffix = '-' + keySuffix;
    }

   if(ap.filter((item : any) => item.key === "buro-detay").length > 0){
      authKey = "calisan" + keySuffix;
    } else if(ap.filter((item : any) => item.key === "avukat-detay").length > 0){
      authKey = "avukat" + keySuffix;
    }

    if (!authKey) {
      return false;
    }

    return this.$helper.isAuthorized(authKey)
  }
}
