
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {NufusCuzdaniEntity} from "@/entity/NufusCuzdaniEntity";

@Component({
  components: {}
})
export default class NufusCuzdaniCard extends Mixins(ObjectInputMixin) {
  localValue!: NufusCuzdaniEntity;
}
