
import {Component, Mixins, Prop} from "vue-property-decorator";
import {AvukatKimlikKartiEntity} from "@/entity/AvukatKimlikKartiEntity";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {AvukatEntity} from "@/entity/AvukatEntity";
import {KisiEntity} from "@/entity/KisiEntity";

@Component({
  components: {}
})
export default class AvukatKimlikKartiCard extends Mixins(ObjectInputMixin) {
  @Prop() item!: AvukatEntity | KisiEntity;
  localValue!: AvukatKimlikKartiEntity;
}
