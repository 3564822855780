

import {Component, Prop, Vue, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import SehirPicker from "@/components/pickers/SehirPicker.vue";
import IlcePicker from "@/components/pickers/IlcePicker.vue";

@Component({
  components: {IlcePicker, SehirPicker}
})
export default class NufusCuzdaniForm extends ObjectInputMixin {


}
